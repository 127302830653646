.goods-margin-bottom {
  margin-bottom: 20px;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.w-70 {
  width: 70%;
}
.w-30 {
  width: 30%;
}
.unitSelectWidth.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 120px;
}
.unitDelete {
  margin-top: 45px;
}
