/* .finishedGoodsTab .ant-tabs-nav .ant-tabs-tab {
  background-color: var(--primary) !important;
} */
.finishedGoodsTab .ant-tabs-nav .ant-tabs-tab-active {
  background-color: var(--primary) !important;
}
.finishedGoodsTab.ant-tabs {
  color: black;
  background-color: white !important;
}
.finishedGoodsTab-p {
  padding: 10px;
}
.finished-goods-bottom {
  margin-bottom: 10px;
}
.finished-goods-closing-bg {
  background-color: var(--highlight);
}
.finished-goods-collapse.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #e6ebec !important;
}
.border-with-no-border-bottom {
  border: 1px solid #e6ebec;
  border-bottom: none; /* Removes the bottom border */
  padding: 10px;
}
.border-with-no-padding {
  border: 1px solid #e6ebec;
}
