.filter-drop-down.ant-menu-inline.ant-menu-root .ant-menu-item {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* padding: 10px; */
}
.filter-drop-down.ant-menu-light.ant-menu-root.ant-menu-inline {
  padding: 10px;
  background-color: var(--layout);
  color: var(--primary);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1000;
  position: absolute;
  top: 50px;
  right: 0px;
}
.chckbox-filter-border-bottom {
  border-bottom: 1px solid black;
}
.reset {
  background-color: var(--layout);
}
.reset.ant-btn-primary:not(:disabled):hover {
  background-color: var(--layout);
  color: var(--primary);
}
.filter-drop-down.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: var(--layout);
}
.filter-drop-down.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: var(--layout);
}
.filter-drop-down.ant-menu-light .ant-menu-item-selected {
  background-color: var(--layout);
}

.filter-drop-down.ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: var(--primary) !important;
}
.filter-max-height {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}
.filer-menu {
  /* height: 400px; */
}
