body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "inter";
  src: url("../public/inter.ttf");
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.h-full {
  height: 100%;
}
.h-full-vh {
  height: 100vh;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.w-content {
  width: fit-content;
}
.gap-2 {
  gap: 10px;
}
.text-start {
  text-align: start;
}
.align-start {
  align-items: self-start;
}
.font-sm {
  font-size: 14px;
}
.flex-col {
  flex-direction: column;
}
.p-1 {
  padding: 10px;
}
.pl-5 {
  padding-left: 5px;
}
.mt-24p {
  margin-top: 24px;
}
.m-inline-24 {
  margin-inline: 24px;
}
.cursor-pointer {
  cursor: pointer;
}
.pl-24 {
  padding-left: 24px;
}
.mx-24 {
  margin-inline: 24px !important;
}

.h-250 {
  height: 250px;
}

.margin-block {
  margin-block: 24px;
}
.h-52 {
  height: 52px;
}
.delete-icon-margin {
  margin-top: 30px;
}

.common-work-break {
  word-break: break-word;
}
.h-52 {
  height: 52px;
}
.mb-10 {
  margin-bottom: 20px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.border {
  border: 1px solid #e6ebec;
}
.textGreen {
  color: #52c41a;
}
.textRed {
  color: red;
}
.h-80vh {
  height: 80vh;
}
.textRight {
  text-align: right;
}
.textHash {
  color: #b5b7c0;
}
.downloadCard {
  font-size: 12px;
  font-weight: 700;
}
