.categoryDropDown.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  font-weight: 700;
  font-size: 18px;
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  color: black;
}
.custom-dropdown.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0px !important;
}
.custom-tab.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: white;
}
.custom-tab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
}
.categoryPadding {
  padding-bottom: 10px;
}
