.customCheckbox.ant-checkbox-group {
  display: flex !important;
  flex-direction: row !important;
}
.vendorAdd {
  margin-left: 15px;
}
.vendorAddTop {
  margin-top: 5px;
}
