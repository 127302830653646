.pieRelative {
  position: relative;
}
.pieAbsolute {
  position: absolute;
  right: 280px;
}
.pieAbsolute1 {
  position: absolute;
  right: 120px;
  font-weight: 600;
}
.pie-margin-bottom {
  margin-bottom: 15px;
}
.pieWrapper {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  height: 40vh;
}
.pieFont {
  font-size: 14px;
}
.pieChartHeight {
  height: 33vh;
}
.pie-legend-margin-bottom {
  margin-bottom: 5px;
}
.legendPie {
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
}
