.categoryUnits {
  padding: 5px;
  background-color: var(--highlight);
}
.categoryActive {
  color: var(--green);
}
.categoryInActive {
  color: red;
}
