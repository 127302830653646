.cancel-order-div-2 {
  background-color: var(--layout);
}
.cancel-order-status-title {
  margin-block: 24px;
  font-size: 18px;
  font-weight: 600;
}
.cancel-order-border-radius {
  border-radius: 8px;
}
.cancel-order-detail-title {
  font-size: 16px;
  font-weight: 600;
}
.attach-icon {
  display: flex !important;
  align-self: center;
}
.cancel-pdf {
  width: 200px;
  background-color: #edf9f9;
  padding: 10px;
  border-radius: 5px;
}
.cancel-order-btn {
  color: var(--secondary);
  background-color: var(--primary);
  height: 48px;
}

.bottom-border {
  border-bottom: solid #e6ebec 1px;
}
.close-order-padding {
  padding: 10px;
}
.close-checkbox {
  background-color: var(--layout);
}
.close-order-m-b {
  margin-bottom: 10px;
}
.file-delete {
  cursor: pointer;
}
.close-margin-top {
  margin-top: 10px;
}
.close-order-div-color {
  background-color: white;
}
.close-order-div-color-2 {
  background-color: var(--layout);
}
.close-order-div-color-3 {
  background-color: var(--layout);
  opacity: 0.4;
}
.close-add-button {
  border-color: var(--primary);
  color: var(--primary);
}
.close-save-button {
  background-color: var(--primary);
  color: white;
}
.select-all {
  color: var(--primary);
  margin-bottom: 10px;
}
.selected-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7cfc00;
  border-color: #7cfc00;
}
.special-consideration {
  margin-bottom: 10px;
}
