.orders-title {
  /* font-size: 32px;
  font-weight: 700; */
  padding: 10px;
  margin-inline: 24px;
}
.p-1 {
  padding: 10px;
}
.p-5 {
  padding: 5px;
}
.mt-24 {
  margin-top: 10px;
}
.order-main-div-2 {
  padding-right: 20px;
  padding-top: 10px;
}
.order-tabs {
  background: white;
  height: fit-content;
  padding: 8px;
  /* margin-inline: 3px; */
  border-radius: 5px;
  width: 140px;
  text-align: center;
  cursor: pointer;
}
.order-tabs-active {
  background: var(--primary);
  height: fit-content;
  padding: 8px;
  color: white;
  width: 140px;
  text-align: center;
  cursor: pointer;
  /* margin-inline: 3px; */
  border-radius: 5px;
}
.order-tab-main-div {
  display: flex;
  margin-right: 24px;
  gap: 5px;
}

.sales-title {
  padding: 10px;
  margin-inline: 24px;
}
.download-btn-color {
  background-color: var(--filter-btn-color);
  display: flex;
  align-items: center;
}
.p-10 {
  padding: 10px;
  background-color: var(--header);
  margin-inline: 24px;
  border-radius: 10px;
}
.primary-btn {
  background-color: var(--primary);
  color: var(--secondary);
  height: 44px;
  margin-right: 24px;
}
.sale-profile {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.sale-profile-gap {
  margin-inline: 5rem;
}
.sale-profile-gap-1 {
  margin-left: 8rem;
}
.sale-profile-main-div {
  background-color: var(--layout);
  padding: 10px;
  margin-block: 24px;

  border-radius: 10px;
}
.sales-profile-p-10 {
  padding: 24px;
}
.sales-profile-title-1 {
  font-size: 18px;
  font-weight: 700;
}
.sales-profile-title-2 {
  font-size: 13px;
  font-weight: 400;
  color: var(--placeholder);
}
.sales-profile-title-3 {
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: 720px) {
  .resp-table {
    display: none;
  }
}
@media (max-width: 720px) {
  .search-resp {
    display: none;
  }
}
@media (min-width: 720px) {
  .resp-search {
    display: none;
  }
}

@media (max-width: 730px) {
  .sm-none {
    display: none;
  }
  .sm-only {
    display: block;
  }
}
@media (min-width: 730px) {
  .sm-only {
    display: none;
  }
}
/* .drawer-width {
  width: 500px !important;
}
@media (max-width: 720px) {
  .drawer-width {
    width: 100% !important;
  }
} */
@media (max-width: 760px) {
  .order-tab-main-div {
    display: none;
  }
}
@media (max-width: 760px) {
  .order-tab-main-div-2 {
    display: flex !important;
  }
}
.order-tab-main-div-2 {
  display: none;
}
.order-tab-main-div-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 760px) {
  .order-tab-main-div-3 {
    flex-direction: column;
    align-items: baseline;
  }
}
