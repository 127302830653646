.warehouse-nobg-button {
  background-color: white;
}
.warehouse-tag {
  border-radius: 20px;
}
.commonOption {
  background-color: var(--highlight) !important;
}
.commonDidider.ant-divider-horizontal {
  margin: 0px;
}
