.mainWrap {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 24px;
}
.add-specification {
  color: var(--blue);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-block: 10px;
}
.specification-wrapper {
  border-top: 1px solid;
  border-bottom: 1px solid black;
  border-top: dashed;
  border-bottom: dashed;
  border-color: var(--border-color);
  /* border: dashed; */
}
.transparent {
  background-color: white;
  color: black;
  border-color: var(--footer);
  font-weight: 400;
  z-index: 1;
}
.icon {
  width: 16px;
  height: 16px;
}
.delete-specification {
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding-block: 10px;
}

.categoryMAnandatory {
  color: var(--red);
}
.categoryLabel {
  font-size: 14px;
  color: black;
  font-weight: 600;
}
.icon-trash {
  margin-left: 10px;
  margin-top: 10px;
}
.z-index-Button {
  z-index: 100;
}
.css-1nmdi5-menu {
  z-index: 1000000000;
}
