.viewall {
  color: var(--blue);
  font-weight: 500;
}
.radioText.ant-radio-wrapper span.ant-radio + * {
  color: #262626;
  color: 14px;
  font-weight: 500;
}
.dashboardPaddingBottom {
  padding-bottom: 15px;
}
