.ant-layout .ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  /* width: 100px; */
  background-color: var(--primary);
}
/* .ant-layout .ant-layout-sider-light {
  background: #ffffff;
  width: 100px;
} */
.ant-layout .ant-layout-sider-light {
  background-color: var(--primary);
  /* width: 100px !important; */
  flex: none !important;
  /* min-width: 100px !important; */
  max-width: none !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item {
  flex-direction: column;
  height: 100%;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  border-radius: 0px;
  padding-left: 0px !important;
  padding-inline: 0px;
  padding-top: 10px;
}
.ant-menu-light.ant-menu-root.ant-menu-inline {
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ant-menu-light .ant-menu-item-selected {
  color: var(--primary);
}
/* .ant-menu-light .ant-menu-item-selected:hover {
  color: var(--primary);
} */
.ant-menu-light {
  color: var(--secondary);
}
.ant-menu-light:hover {
  color: var(--secondary);
}

.ant-layout .ant-layout-header {
  background-color: var(--header);
}
.ant-form-item .ant-form-item-explain-error {
  text-align: start;
}
.ant-form-vertical .ant-form-item-label > label {
  font-size: 14px;
  font-weight: 700;
  color: var(--title-text);
}
.ant-btn-primary:not(:disabled):hover {
  background-color: var(--primary);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-color) inset !important;
}

.ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-item-active):hover {
  color: var(--secondary) !important;
}
/* .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content:hover {
  color: var(--secondary);
} */
.ant-table-thead .ant-table-cell {
  background-color: var(--header) !important;
  color: var(--primary) !important;
}
.antd-table-row {
  background-color: var(--header) !important;
}
.ant-card .ant-card-body {
  background-color: var(--primary);
  color: white;
  border-radius: 8px;
}
.ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  background-color: var(--primary);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  color: white;
  background-color: var(--primary);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background-color: white;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 8px;
}
.ant-input:focus {
  border-color: var(--antd-border) !important;
  box-shadow: none !important;
  /* border: none !important; */
  border-inline-end-width: none;
}
.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: var(--secondary);
}
.ant-input:hover {
  border-color: var(--primary);
}
/* .ant-select-selector {
  border: 1px solid var(--primary) !important;
} */
.ant-layout * {
  font-family: "Inter", sans-serif !important;
}
.ant-layout-content {
  max-height: 100vh;
  overflow-y: auto;
}
.ant-layout .ant-layout-sider-zero-width-trigger {
  top: 13px;
  display: none;
}
.ant-layout .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  background-color: var(--layout);
  border: none;
}
/* Add a CSS media query for mobile devices */
@media (max-width: 767px) {
  /* Override the default responsive transform for the Sider component */
  .ant-layout .ant-layout-sider {
    transition: none !important;
    /* display: none; */
  }
}
@media (max-width: 767px) {
  /* Override the default responsive transform for the Sider component */
  .ant-layout .ant-layout-sider-zero-width-trigger {
    transition: none !important;
    display: none !important;
  }
}
@media (max-width: 767px) {
  .ant-layout-header {
    padding-inline: 18px;
  }
}
.ant-drawer-content-wrapper {
  width: 100% !important;
}
@media (min-width: 600px) {
  .ant-drawer-content-wrapper {
    width: 600px !important;
  }
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.ant-input-affix-wrapper .ant-input-suffix {
  display: none;
}
.ant-menu .ant-menu-item .ant-menu-item-icon + span {
  margin-inline-start: 0px !important;
}
.css-egldij-Input {
  visibility: visible !important;
}
.ant-form-item .ant-form-item-feedback-icon-error {
  display: none;
}
/* .css-tfygzr {
  visibility: visible !important;
} */
.ant-pagination .ant-pagination-item-active a {
  color: var(--primary);
}
.ant-pagination .ant-pagination-item-active {
  border-color: var(--primary);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: white;
}
.css-b62m3t-container {
  height: 33px;
}
.ant-spin .ant-spin-dot-item {
  background-color: var(--primary);
}
.ant-drawer .ant-drawer-mask {
  background-color: var(--primary);
  opacity: 0.25;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-rate .ant-rate-star-second {
  color: #00000033;
}
.commonOption.ant-dropdown .ant-dropdown-menu {
  background-color: aqua;
}
.customInput.ant-input {
  height: 40px;
}
.customSelect.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px;
  display: flex;
  align-items: center;
}
.customPicker.ant-picker {
  height: 40px;
}
.ant-popover .ant-popover-inner {
  padding: 0px;
}
.ant-menu-vertical > .ant-menu-item {
  height: 20px;
  display: flex;
  align-items: center;
}
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  /* margin-bottom: 0px; */
  overflow: visible;
}
.ant-picker-calendar
  .ant-picker-calendar-header
  .ant-picker-calendar-mode-switch {
  display: none;
}
.ant-picker-calendar-header {
  justify-content: flex-start !important;
}
.ant-picker-calendar.ant-picker-calendar-full
  .ant-picker-calendar-date-content {
  align-items: center;
  display: flex;
  justify-content: center;
}
.wealay-primary-btn.ant-btn-default:not(:disabled):hover {
  background-color: var(--primary);
  color: white;
}
.ant-menu-inline .ant-menu-item {
  margin-inline: 0px;
}
