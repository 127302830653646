.popover-text {
  color: var(--primary);
  background-color: var(--highlight);
}
.popover-bg .ant-popover .ant-popover-inner {
  padding: 0px;
}

.popover-text :hover {
  color: var(--primary);

  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: var(--highlight);
}

.sub-menu.ant-menu-vertical.ant-menu-root {
  background-color: var(--highlight);
  border-inline-end: none;
}
.sub-menu .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: var(--highlight) !important;
  font-weight: 600;
  color: var(--primary) !important;
}
.ant-popover .ant-popover-inner {
  background-color: var(--highlight);
}
.sub-menu.ant-menu-light .ant-menu-item-selected {
  font-weight: 600;
}

.active-sub {
  color: var(--primary);
}
.inactive-sub {
  color: var(--secondary);
}
.active-sub {
  color: var(--primary);
}
